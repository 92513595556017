import * as React from 'react'
import Layout from '../components/layout'
import '../styles/global.css'
import { Link } from 'gatsby'
import {Helmet} from "react-helmet";



export default function Services() {
    return (
    <Layout pageTitle="Services">
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Services - USI</title>
        <meta name="description" content="A full list of services we provide."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

    <div class="mx-auto py-16 bg-blue-900 pt-24 lg:pt-32">
      <div class="px-20">
      <div class="float-none ml-24 mb-16 sm:ml-0 sm:mb-0 md:float-right">
        <Link to="/" class="marrow goback text-xs md:text-sm text-white">Go Back
          <span></span>
        </Link>
      </div>
      <h1 class="text-4xl md:text-7xl text-white font-heading">What we do everyday</h1>


      <div class="flex flex-wrap -mx-6 overflow-hidden sm:-mx-6 md:-mx-6 lg:-mx-6 xl:-mx-6">

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-16 sm:px-6 sm:w-1/2 md:my-16 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-16 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">01</span>Initial
            Scoping
          </h2>
          <div class="ml-9">
            <p class="text-xl mt-6 leading-8 text-white">Requirements Scoping</p>
            <p class="text-xl leading-8  text-white">Policy Scoping </p>
            <p class="text-xl leading-8  text-white">Benchmarking </p>
            <p class="text-xl leading-8 text-white">Problem Definition</p>
            <p class="text-xl leading-8  text-white">Brief Definition</p>
            <p class="text-xl leading-8  text-white">Process Scoping</p>
            <p class="text-xl leading-8  text-white">Market Analysis</p>
            <p class="text-xl leading-8 text-white">Opportunity Appraisals</p>
            <p class="text-xl leading-8  text-white">Case for Change</p>
            <p class="text-xl leading-8 text-white">Project Planning</p>
            <p class="text-xl leading-8  text-white">Context modelling and analysis</p>
            <p class="text-xl leading-8  text-white">Market Segments</p>
            <p class="text-xl leading-8  text-white">Procurement Strategy</p>
            <p class="text-xl leading-8 text-white">Feasibility</p>
          </div>

        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-16 sm:px-6 sm:w-1/2 md:my-16 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-16 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">02</span>Design
          </h2>
          <div class="ml-9">
            <p class="text-xl mt-6 leading-8 text-white">Branding</p>
            <p class="text-xl leading-8  text-white">Copywriting </p>
            <p class="text-xl leading-8  text-white">Design Strategy</p>
            <p class="text-xl leading-8 text-white">Service Design</p>
            <p class="text-xl leading-8 text-white">Product development </p>
            <p class="text-xl leading-8  text-white">Urban Strategies</p>
            <p class="text-xl leading-8  text-white">Meanwhile Strategies</p>
            <p class="text-xl leading-8  text-white">Pop Up Interventions</p>
            <p class="text-xl leading-8 text-white">Architectural Design</p>
            <p class="text-xl leading-8  text-white">CGI Visual and Illustration</p>
            <p class="text-xl leading-8 text-white">Technology Applications</p>
            <p class="text-xl leading-8  text-white">UX Design</p>
            <p class="text-xl leading-8  text-white">Graphic Design</p>
            <p class="text-xl leading-8  text-white">Web Design</p>
            <p class="text-xl leading-8 text-white">Web/Digital Platform Development</p>
          </div>

        </div>


        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-16 sm:px-6 sm:w-1/2 md:my-16 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-16 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3 ">03</span>Engagement
          </h2>

          <div class="ml-8">
            <h3 class="text-xl italic text-white">Stakeholder, community, political, sector, international</h3>
            <p class="text-xl mt-6 leading-8 text-white">Literature review</p>
            <p class="text-xl leading-8  text-white">Identification</p>
            <p class="text-xl leading-8  text-white">Planning</p>
            <p class="text-xl leading-8 text-white">Matrix/Auditing</p>
            <p class="text-xl leading-8  text-white">Interviews/meetings</p>
            <p class="text-xl leading-8  text-white">Local to international modelling</p>
            <p class="text-xl leading-8  text-white">Creative workshops and content</p>
            <p class="text-xl leading-8 text-white">Engagement Platforms</p>
            <p class="text-xl leading-8  text-white">Analysis (written, visual)</p>
            <p class="text-xl leading-8 text-white">Insight Reporting</p>

          </div>

        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">

          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xll text-white"><span
              class="limegreen text-base mr-3">04</span>Strategic
            Vision</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Mission Vision</p>
            <p class="text-xl leading-8  text-white">Value Development</p>
            <p class="text-xl leading-8  text-white">Trend and Growth analysis</p>
            <p class="text-xl leading-8 text-white">Key Metrics</p>
            <p class="text-xl leading-8  text-white">Thematics</p>
            <p class="text-xl leading-8  text-white">High Level Vision</p>
            <p class="text-xl leading-8  text-white">Environmental Strategy</p>
            <p class="text-xl leading-8 text-white">Establishing principles and frameworks</p>
            <p class="text-xl leading-8  text-white">Roadmaps</p>
            <p class="text-xl leading-8 text-white">Resource Mapping</p>

          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">05</span>Communication
          </h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Events Management</p>
            <p class="text-xl leading-8  text-white">Communication Planning</p>
            <p class="text-xl leading-8  text-white">PR Liason</p>
            <p class="text-xl leading-8 text-white">Marketing Material (Visual & Written)</p>
            <p class="text-xl leading-8  text-white">Communication Management</p>
            <p class="text-xl leading-8  text-white">Performance and Events Curation</p>
            <p class="text-xl leading-8  text-white">Public speaking / conference</p>


          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">06</span>Management
          </h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Procurement Management</p>
            <p class="text-xl leading-8  text-white">Project Management</p>
            <p class="text-xl leading-8  text-white">Multi Disciplinary team curation</p>
            <p class="text-xl leading-8 text-white">Multi Disciplinary team management</p>
            <p class="text-xl leading-8  text-white">Tender brief writing</p>
            <p class="text-xl leading-8  text-white">Multi - Stakeholder Management</p>
            <p class="text-xl leading-8  text-white">Designing and Specification Briefing</p>
            <p class="text-xl leading-8  text-white">Stakeholder Governance Models</p>


          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">07</span>Intervention
            Delivery</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">3D Printing</p>
            <p class="text-xl leading-8  text-white">Manufacture</p>
            <p class="text-xl leading-8  text-white">Installations</p>
            <p class="text-xl leading-8  text-white">Products</p>
            <p class="text-xl leading-8  text-white">Lighting</p>
            <p class="text-xl leading-8  text-white">Architectural</p>
          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">08</span>Economics &
            Funding</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Cost Structure</p>
            <p class="text-xl leading-8  text-white">Operating Models</p>
            <p class="text-xl leading-8  text-white">Identify Funding Streams</p>
            <p class="text-xl leading-8  text-white">Project Budgeting</p>
          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">09</span>KPI
            Measurements</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Measurement Models</p>
            <p class="text-xl leading-8  text-white">Create indicators and Kpi's</p>
            <p class="text-xl leading-8  text-white">Success Metrics</p>
            <p class="text-xl leading-8  text-white">Evaluation Reports</p>
          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">10</span>Cultural</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Programming</p>
            <p class="text-xl leading-8  text-white">Curatorial </p>
            <p class="text-xl leading-8  text-white">Cultural ideas / content</p>

          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
          <h2 class="text-2xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl text-white"><span
              class="limegreen text-base mr-3">11</span>Opportunity
            Areas</h2>

          <div class="ml-8">
            <p class="text-xl mt-6 leading-8 text-white">Partnership Brokering</p>
            <p class="text-xl leading-8  text-white">Establishing Networks</p>
          </div>
        </div>

        <div
          class="my-6 px-6 w-full overflow-hidden sm:my-6 sm:px-6 sm:w-1/2 md:my-6 md:px-6 md:w-1/2 lg:my-6 lg:px-6 lg:w-1/3 xl:my-6 xl:px-6 xl:w-1/3">
        </div>


      </div>
      </div>
    </div>  
      </Layout>
    )
  }
  